<template>
    <div class="login login-v2" data-pageload-addclass="animated fadeIn">
      <!-- begin brand -->
      <div class="login-header">
        <div class="brand">
          <span class="logo"></span> <b>P2C</b>
          <small>responsive bootstrap 4 admin template</small>
        </div>
      </div>
      <div class="login-content">
        <form class="margin-bottom-0">
          <form-group-thux
            :validator="$v.form.email"
            :label="'auth.email' | translate">
            <template slot-scope="{ attrs, listeners }">
              <b-form-input
                type="email"
                v-bind="attrs"
                v-on="listeners"
                v-model="form.email" />
            </template>
          </form-group-thux>
          <div class="text-justify">{{ 'auth.forgotPassword.info' | translate }}</div>
          <div class="login-buttons mt-5 d-flex justify-content-between align-items-center">
            <router-link :to="{ name: AUTH_ROUTES.LOGIN }">
                <button class="btn btn-default btn-lg">{{ 'Cancel' | translate }}</button>
              </router-link>
            <button
              class="btn btn-success btn-lg"
              :disabled="$v.form.$invalid"
              @click.prevent="onSubmit()">
              {{ 'Confirm' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import PageOptions from '../../config/PageOptions.vue'
import { ROUTES as AUTH_ROUTES } from './router'
import { TYPES as AUTH_TYPES } from './store'

export default {
  data () {
    return {
      form: {},
      AUTH_ROUTES
    }
  },
  created () { },
  beforeRouteLeave (to, from, next) {
    PageOptions.pageEmpty = false
    next()
  },
  methods: {
    ...mapActions({
      ...AUTH_TYPES.AUTH
    }),
    onSubmit () {
      if (!this.$v.$invalid) {
        this.resetPassword(this.form).then(
          response => {
            this.$router.replace({ name: AUTH_ROUTES.LOGIN })
          }
        ).catch(
          error => {
            this.$store.dispatch('notifyError', { error: error.response.data })
          }
        )
      }
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  }
}
</script>
